import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableFooter,
  TableContainer,
  TableCell,
  TableHead,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Wrapper from '../../views/wrapper/Wrapper';
import { useTransaction } from '../../hook/useTransaction';
import { Div, Background, Button } from './styled-component';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import TransactionItem from './TransactionItem';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { createParams, createParamsExport, translateStatus, translateType } from './utils';
import dayjs from 'dayjs'

const useStyles = makeStyles({
  select: {
    '& #filter-status:focus, & #transaction-status:focus': {
      background: 'none',
    },
  },
  icon_btn: {
    cursor: 'pointer',
  },
  pagination_btn: {
    '& button:focus': {
      outline: 0,
    },
  },
  input_label: {
    fontSize: '8px',
  },
});

const COLUMNS = 12;
const Transactions = () => {
  const currentDate = dayjs();
  const { defaultPage, defaultSize, error, loading, transactions, dispatchGet, totalItem, dispatchExport } = useTransaction();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [status, setStatus] = useState('ALL');
  const [type, setType] = useState('ALL');
  const [userIdCard, setUserIdCard] = useState('');
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [urlSlip, setUrlSlip] = useState(null);
  const [isEditTable, setIsEditTable] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState(currentDate);
  const [filterCreatedDate, setFilterCreatedDate] = useState(null);
  const [isClearActive, setIsClearActive] = useState(false);

  const classes = useStyles();
  const { user } = useAuthState(auth);

  useEffect(() => {
    dispatchGet(`?size=${defaultSize}&page=${defaultPage}&start_date=${currentDate.format("YYYY-MM-DD")}`);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsEditTable(false);
    const params = createParams(status, userIdCard, name, tel, newPage + 1, size, filterStartDate, filterCreatedDate, type);
    dispatchGet(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(0);
    setIsEditTable(false);
    const params = createParams(status, userIdCard, name, tel, 1, event.target.value, filterStartDate, filterCreatedDate, type);
    dispatchGet(params);
  };

  const filterStatus = (event) => {
    setStatus(event.target.value);
    setPage(0);
    setIsEditTable(false);
    if (event.target.value !== 'ALL') {
      setIsClearActive(true);
    }
  };

  const filterType = (event) => {
    setType(event.target.value);
    setPage(0);
    setIsEditTable(false);
    if (event.target.value !== 'ALL') {
      setIsClearActive(true);
    }
  };

  const filterUser = (event) => {
    setUserIdCard(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };
  const filterName = (event) => {
    setName(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };
  const filterTel = (event) => {
    setTel(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };

  const search = () => {
    setPage(0);
    setIsEditTable(false);
    const params = createParams(status, userIdCard, name, tel, 1, size, filterStartDate, filterCreatedDate, type);
    dispatchGet(params);
  };

  const showSlip = (url) => {
    setOpenModal(true);
    setUrlSlip(url);
  };

  const onFilterStartDateChange = (date) => {
    setFilterStartDate(date);
    setIsEditTable(false);
    if (date) {
      setIsClearActive(true);
    }
  };

  const onFilterCreatedDateChange = (date) => {
    setFilterCreatedDate(date);
    setIsEditTable(false);
    if (date) {
      setIsClearActive(true);
    }
  };

  const clearFilter = () => {
    setStatus('ALL');
    setType('ALL');
    setUserIdCard('');
    setName('');
    setTel('');
    setFilterStartDate(null);
    setFilterCreatedDate(null);
    setIsEditTable(false);
    setIsClearActive(false);
  };

  const exportData = (withFilter) => {
    let params = '';
    if (withFilter) {
      params = createParamsExport(status, userIdCard, name, tel, filterStartDate, filterCreatedDate, filterCreatedDate, type);
    }
    dispatchExport(params);
  };

  return (
    <Wrapper>
      <h1>รายการชำระ</h1>
      <Div>
        <Grid container item>
          <Background>
            <Div style={{ marginBottom: '3rem', justifyContent: 'space-between' }}>
              <div>
                <KeyboardDatePicker
                  style={{ marginRight: '2rem' }}
                  variant="inline"
                  format="DD/MM/yyyy"
                  id="date-picker-inline"
                  label="วันที่ชำระ"
                  value={filterStartDate}
                  onChange={onFilterStartDateChange}
                />
                <KeyboardDatePicker
                  style={{ marginRight: '2rem' }}
                  variant="inline"
                  format="DD/MM/yyyy"
                  id="date-picker-inline"
                  label="วันที่ทำรายการ"
                  value={filterCreatedDate}
                  onChange={onFilterCreatedDateChange}
                />
                <FormControl style={{ marginRight: '2rem' }}>
                  <InputLabel id="filter-status-label">สถานะ</InputLabel>
                  <Select className={classes.select} labelId="filter-status-label" id="filter-status" value={status} onChange={filterStatus}>
                    <MenuItem value="ALL">{translateStatus('ALL')}</MenuItem>
                    <MenuItem value="PENDING">{translateStatus('PENDING')}</MenuItem>
                    <MenuItem value="CONFIRMED">{translateStatus('CONFIRMED')}</MenuItem>
                    <MenuItem value="CANCELED">{translateStatus('CANCELED')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ marginRight: '2rem' }}>
                  <InputLabel id="filter-type-label">ประเภท</InputLabel>
                  <Select className={classes.select} labelId="filter-type-label" id="filter-type" value={type} onChange={filterType}>
                    <MenuItem value="ALL">{translateType('ALL')}</MenuItem>
                    <MenuItem value="INSTALLMENT">{translateType('INSTALLMENT')}</MenuItem>
                    <MenuItem value="DOWN_PAYMENT">{translateType('DOWN_PAYMENT')}</MenuItem>
                    <MenuItem value="COUPON">{translateType('COUPON')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <Button className="search" type="button" onClick={() => exportData(true)} style={{ alignSelf: 'flex-end' }}>
                  Export by filters
                </Button>
                <Button className="search" type="button" onClick={() => exportData(false)} style={{ alignSelf: 'flex-end' }}>
                  Export All
                </Button>
              </div>
            </Div>
            <Div style={{ marginBottom: '3rem' }}>
              <TextField style={{ marginRight: '10px' }} label="เลขบัตรประชาชน" onChange={filterUser} value={userIdCard} />
              <TextField style={{ marginRight: '10px' }} label="ชื่อลูกค้า" onChange={filterName} value={name} />
              <TextField label="เบอร์โทร." onChange={filterTel} value={tel} />
              <div>
                <Button className="search" type="button" onClick={() => search()} style={{ marginLeft: '2rem' }}>
                  ค้นหา
                </Button>
                <Button className="clear" type="button" onClick={() => clearFilter()} disabled={!isClearActive}>
                  ล้าง
                </Button>
              </div>
            </Div>
            <Modal open={openModal} setOpen={setOpenModal} noButton heightAuto>
              {urlSlip ? <img width="400px" src={urlSlip} alt="" /> : <p>image cannot load</p>}
            </Modal>

            <TableContainer>
              <Table style={{ overflow: 'hidden' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>เลขบัตรประชาชน</TableCell>
                    <TableCell>ชื่อ</TableCell>
                    <TableCell>ประเภท</TableCell>
                    <TableCell>วันที่ชำระ</TableCell>
                    <TableCell>วันที่ทำรายการ</TableCell>
                    <TableCell>จำนวนเงิน(บาท)</TableCell>
                    <TableCell>สลิป</TableCell>
                    <TableCell align="center">สถานะตรวจสอบ</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="left" colSpan={isEditTable ? COLUMNS - 5 : COLUMNS - 6}>
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={COLUMNS}>
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                  {transactions.length > 0 &&
                    transactions.map((item, index) => (
                      <TransactionItem
                        key={item._id}
                        id={item._id}
                        idCard={item?.user?.id_card}
                        name={item?.user?.general.fullname}
                        type={translateType(item?.type)}
                        transactionDate={item.transaction_date}
                        createdDate={item.created_date}
                        totalAmount={item.total_amount}
                        slipUrl={item.slip_url}
                        canUpdate={item.can_update}
                        onClickSlip={showSlip}
                        statusSlip={item.slip?.status || '-'}
                        status={item.status}
                        statusLabel={translateStatus(item.status)}
                        remark={item.remark}
                        onEditTable={setIsEditTable}
                        page={page}
                        size={size}
                        updatedBy={user.displayName}
                        filter={{ status, userIdCard, name, tel, startDate: filterStartDate, createdDate: filterCreatedDate }}
                      />
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      className={classes.pagination_btn}
                      rowsPerPageOptions={[10, 20, 30, 40, 100]}
                      colSpan={isEditTable ? COLUMNS + 1 : COLUMNS}
                      count={totalItem}
                      rowsPerPage={size}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Modal open={loading} noButton heightAuto style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
              <Loading />
            </Modal>
          </Background>
        </Grid>
      </Div>
    </Wrapper>
  );
};

export default Transactions;
